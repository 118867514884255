class AddressSelector {
    constructor(element) {
        this.selectElement = element;
        const jsonElement = document.getElementById('current-user-addresses');
        const json = jsonElement ? jsonElement.innerHTML : '';
        this.addresses = json ? JSON.parse(json) : [];

        this.selectElement.addEventListener('input', (event) => {
            console.log(event);
            this.onSelectChange(event);
        });
    }

    setAddress(index) {
        const address = this.addresses[index];

        if(!address) {
            return;
        }

        const map = {
            address: "adress",
            city: "city",
            company: "company",
            country: "country",
            email: "email",
            first_name: "firstname",
            last_name: "lastname",
            phonenumber: "phone",
            zipcode: "postal_code",
        }

        for (const [key, value] of Object.entries(map)) {
            const element = document.querySelector(`[name="${value}"]`);
            if (element) {
                element.value = address[key];

                // trigger change event
                const event = new Event('input');
                element.dispatchEvent(event);
            }
        }
    }

    onSelectChange(event) {
        const value = event.target.value;

        if(Number.isInteger(value)) {
            return;
        }

        this.setAddress(+value);
    }
}


window.addEventListener('load', function() {
    const select = document.getElementById('address_selector');

    if(!select) {
        return;
    }

    new AddressSelector(select);
});