class Modal {
    constructor(element) {
        this.valid = false;
        this.element = element;
        this.content = this.element.querySelector('.modal-content');
        if (!this.element.hasAttribute('modal')) {
            //invalid
            this.element.setAttribute('modal-state', 'invalid');
            return;
        } else {
            this.element.setAttribute('modal-state', 'closed');
        }

        this.name = this.element.getAttribute('modal');
        this.valid = true;

        if(this.element.hasAttribute('modal-start') && this.element.getAttribute('modal-start') === 'opened') {
            setTimeout(() => this.open(), 500);
        }



        this.init().then(r => {});
    }

    async init() {
        // listen for clicks on the modal
        this.element.addEventListener('click', (e) => {
            // check if the click was on the the.content
            const target = e.target;
            if (target === this.content) {
                // the click was on the the.content
                return;
            }

            // check inside
            if (this.content.contains(target)) {
                // the click was inside the the.content
                return;
            }

            // the click was outside the the.content
            this.close();
        });

        const triggers = Array.from(document.querySelectorAll(`[modal-trigger="${this.name}"], [modal="${this.name}"] [modal-trigger]`));
        triggers.forEach(i => {
            i.addEventListener('click', (e) => {
                e.preventDefault();
                this.toggle();
            });
        });

        const closeButtons = Array.from(this.element.querySelectorAll(`[modal-close="${this.name}"], [modal="${this.name}"] [modal-close]`));
        closeButtons.forEach(i => {
            i.addEventListener('click', (e) => {
                e.preventDefault();
                this.close();
            });
        });

        const openButtons = Array.from(this.element.querySelectorAll(`[modal-open="${this.name}"], [modal="${this.name}"] [modal-open]`));
        openButtons.forEach(i => {
            i.addEventListener('click', (e) => {
                e.preventDefault();
                this.open();
            });
        });
    }

    open() {
        this.element.setAttribute('modal-state', 'opened');
    }

    close() {
        this.element.setAttribute('modal-state', 'closed');
    }

    toggle() {
        if (this.element.getAttribute('modal-state') === 'opened') {
            this.close();
        } else {
            this.open();
        }
    }
}

window.addEventListener('load', () => {
    const elements = Array.from(document.querySelectorAll('[modal]'));
    const modals = elements.map(i => new Modal(i)).filter(i => i.valid);
    console.log(modals);
    console.log('Modals loaded');
});
